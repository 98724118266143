<template>
  <div class="p-grid p-justify-center p-formgrid">
    <div class="p-col-12 p-md-6 p-lg-4">
      <h3>Редактирование профиля</h3>
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="name">Имя</label>
            <InputText
              id="name"
              v-model.trim="name.val"
              :required="true"
              @blur="clearValidity('name')"
              :class="!name.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="lastname">Фамилия</label>
            <InputText
              id="lastname"
              v-model.trim="lastname.val"
              :required="true"
              @blur="clearValidity('lastname')"
              :class="!lastname.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="role">Роль</label>
            <InputText id="role" v-model="allRoles[role]" disabled />
          </div>
          <div class="p-field">
            <label for="email">E-mail</label>
            <InputText
              type="email"
              id="email"
              @blur="clearValidity('email')"
              :class="!email.isValid ? 'p-invalid' : ''"
              v-model.trim="email.val"
              :required="true"
            />
          </div>
          <div class="p-field">
            <label for="phone">Телефон</label>
            <InputMask
              mask="+7-999-999-99-99"
              id="phone"
              v-model="phone.val"
              :required="true"
              @blur="clearValidity('phone')"
              :class="!phone.isValid ? 'p-invalid' : ''"
              :autoClear="false"
            />
          </div>
          <div class="p-field" v-if="role === ROLE.OPERATOR">
            <label for="ephone">Расширенный телефон</label>
            <InputText
              id="ephone"
              v-model.trim="extensionPhone.val"
              :required="true"
              @blur="clearValidity('extensionPhone')"
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="countriesAndRegions">Выберите регион</label>
            <MultiSelect
              v-model="selectedGroupedCities.val"
              :options="groupedCities"
              optionLabel="label"
              optionGroupChildren="items"
              optionGroupLabel="label"
              placeholder="Выберите регионы"
              :class="!selectedGroupedCities.isValid ? 'p-invalid' : ''"
            >
              <template #optiongroup="slotProps">
                <div class="p-d-flex p-ai-center country-item">
                  <img
                    src="assets/layout/flags/flag_placeholder.png"
                    :class="'flag flag-' + slotProps.option.code.toLowerCase()"
                    class="p-mr-2"
                    width="18"
                  />
                  <div>{{ slotProps.option.label }}</div>
                </div>
              </template>
            </MultiSelect>
          </div>

          <div v-if="role === ROLE.SALES_MANAGER">
            <div class="p-field">
              <label for="company">Выберите компании</label>
              <Dropdown
                :filter="true"
                v-model="selectedCompany"
                :options="companies"
                placeholder="Нажмите, чтобы выбрать клиента для привязки к профилю"
                optionLabel="name"
                emptyFilterMessage="Совпадений не найдено"
                :disabled="!permissions['users']"
              >
                <template #option="slotProps">
                  <div class="employee-item">
                    <div>
                      {{ slotProps.option.name }}
                    </div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label v-if="companiesForProfile.length"
                >Выбранные компании</label
              >
              <div
                v-for="(company, index) in companiesForProfile"
                :key="index"
                class="p-d-flex p-ai-center p-jc-between"
              >
                <div>{{ index + 1 }}. {{ company.name }}</div>
                <div>
                  <Button
                    v-if="permissions['users']"
                    class="pi pi-times p-button-danger delete-button"
                    @click="deleteCompany(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="role === ROLE.ENGINEER">
            <div class="p-field">
              <label for="department">Филиал</label>
              <Dropdown
                v-model="department.val"
                :options="groupedCities"
                optionValue="label"
                optionLabel="label"
                optionGroupChildren="items"
                optionGroupLabel="label"
                placeholder="Выберите филиал"
                :class="!department.isValid ? 'p-invalid' : ''"
              >
                <template #optiongroup="slotProps">
                  <div class="p-d-flex p-ai-center country-item">
                    <img
                      src="assets/layout/flags/flag_placeholder.png"
                      :class="
                        'flag flag-' + slotProps.option.code.toLowerCase()
                      "
                      class="p-mr-2"
                      width="18"
                    />
                    <div>{{ slotProps.option.label }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="p-field">
              <label>Класс инженера</label>
              <Dropdown
                v-model="engineerLevel.val"
                :options="ENGINEER_LEVEL_LIST"
                optionValue="value"
                optionLabel="label"
                placeholder="Выберите класс инженера"
                :class="!engineerLevel.isValid ? 'p-invalid' : ''"
              />
            </div>
            <div class="p-field">
              <label>СНИЛС</label>
              <InputMask
                mask="999-999-999 99"
                v-model="snils.val"
                id="snils"
                @blur="clearValidity('snils')"
                :class="{ 'p-invalid': !snils.isValid }"
                :auto-clear="false"
                unmask
              />
            </div>
            <div class="p-field">
              <label>Оклад</label>
              <InputNumber
                v-model="salary.val"
                id="salary"
                @blur="clearValidity('salary')"
                mode="decimal"
                :min-fraction-digits="2"
                :max-fraction-digits="2"
              />
            </div>
            <div class="p-field">
              <label>Юр. лицо</label>
              <InputText
                v-model.trim="legalName.val"
                id="legalName"
                @blur="clearValidity('legalName')"
              />
            </div>
          </div>

          <Button
            label="Обновить"
            @click="submitForm"
            :disabled="isLoading"
          ></Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import { ROLE, ROLES_LIST } from "@/models/roles";
import { ENGINEER_LEVEL_LIST } from "@/models/engineerLevel";

export default {
  name: "UserDetail",
  data() {
    return {
      ROLE,
      ENGINEER_LEVEL_LIST,
      allRoles: ROLES_LIST,
      name: {
        val: "",
        isValid: true,
      },
      lastname: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      extensionPhone: {
        val: null,
        isValid: true,
      },
      country: {
        val: null,
        isValid: true,
      },
      region: {
        val: null,
        isValid: true,
      },
      department: {
        val: null,
        isValid: true,
      },
      engineerLevel: {
        val: null,
        isValid: true,
      },
      snils: {
        val: null,
        isValid: true,
      },
      salary: {
        val: null,
        isValid: true,
      },
      legalName: {
        val: null,
        isValid: true,
      },
      id: null,
      isLoading: false,
      formIsValid: false,
      role: null,
      loadedUser: {},
      groupedCities: [
        {
          label: "Россия",
          code: "RU",
          items: [],
          regionsForLoad: [],
        },
        {
          label: "Казахстан",
          code: "KZ",
          items: [],
          regionsForLoad: [],
        },
      ],
      selectedGroupedCities: {
        val: [],
        isValid: true,
      },

      selectedCompany: null,
      companiesForProfile: [],
    };
  },
  async created() {
    if (!this.$store.getters["data/regions"].length) {
      await this.$store.dispatch("data/loadRegions");
    }
    for (const region of this.$store.getters["data/regions"]) {
      if (region.country.name === "РОССИЯ") {
        this.groupedCities[0].items.push({
          label: region.name,
          value: region.name,
          filledRegion: region,
        });
      } else {
        this.groupedCities[1].items.push({
          label: region.name,
          value: region.name,
          filledRegion: region,
        });
      }
    }
    await this.loadUser(this.$route.params.id);
  },
  watch: {
    selectedCompany: function (newValue, oldValue) {
      if (newValue) {
        this.companiesForProfile.push(newValue);
        this.selectedCompany = null;
      }
    },
  },
  computed: {
    companies() {
      return this.$store.getters["myprofiles/clients"];
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    userData() {
      return this.$store.getters.userData;
    },
    countryList() {
      return this.$store.getters["data/countries"];
    },
    regionList() {
      if (this.country.val) {
        return this.$store.getters["data/regions"].filter(
          (r) => r.country.uid === this.country.val.uid
        );
      }
      return [];
    },
  },
  methods: {
    deleteCompany(index) {
      this.companiesForProfile.splice(index, 1);
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.lastname.isValid = true;
      this.email.isValid = true;
      this.phone.isValid = true;
      this.extensionPhone.isValid = true;
      this.selectedGroupedCities.isValid = true;
      this.snils.isValid = true;
      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.lastname.val) {
        this.lastname.isValid = false;
        this.formIsValid = false;
      }
      if (!this.phone.val) {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
      if (this.role.val?.value === ROLE.OPERATOR) {
        if (this.selectedGroupedCities.val.length === 0) {
          this.formIsValid = false;
          this.selectedGroupedCities.isValid = false;
        }
      } else {
        this.selectedGroupedCities.isValid = true;
      }
      if (this.snils.val && this.snils.val.length < 11) {
        this.snils.isValid = false;
        this.formIsValid = false;
      }
    },
    markLoadedRegions(responseData) {
      for (const country of this.groupedCities) {
        for (const region of country.items) {
          for (const loadedRegion of responseData.regions) {
            if (loadedRegion.uid === region.filledRegion.uid) {
              this.selectedGroupedCities.val.push(region);
            }
          }
        }
      }
    },
    async loadUser(id) {
      this.isLoading = true;
      try {
        this.selectedGroupedCities.val = [];
        const response = await fetch(`${environment.apiUrl}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();

        this.email.val = responseData.email;
        this.name.val = responseData.firstname;
        this.lastname.val = responseData.lastname;
        this.phone.val = responseData.phone;
        this.role = responseData.role;
        this.loadedUser = responseData;
        this.department.val = responseData?.dep;
        this.engineerLevel.val = responseData?.engineerLevel;
        this.snils.val = responseData?.snils;
        this.salary.val = responseData?.salary;
        this.legalName.val = responseData?.legalName;

        if (this.role === ROLE.SALES_MANAGER && responseData?.companies) {
          this.companiesForProfile = responseData.companies;
        }

        if (this.role === ROLE.OPERATOR) {
          this.extensionPhone.val = responseData.extensionPhone;
        }

        if (responseData?.regions) {
          this.markLoadedRegions(responseData);
        }

        this.id = responseData.id;
        this.company = responseData;
      } catch (err) {
        const error = err.message || "Не получилось загрузить клиента";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке клиента!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      let payloadRegions = [];

      for (const regions of this.selectedGroupedCities.val) {
        payloadRegions.push(regions.filledRegion);
      }

      const actionPayload = {
        ...this.loadedUser,
        id: this.id,
        firstname: this.name.val,
        lastname: this.lastname.val,
        email: this.email.val,
        phone: this.phone.val,
        role: this.role,
        regions: payloadRegions,
      };

      if (this.role === ROLE.OPERATOR) {
        actionPayload.extensionPhone = this.extensionPhone.val;
      }

      if (this.role === ROLE.SALES_MANAGER) {
        actionPayload.companies = this.companiesForProfile;
      }

      if (this.role === ROLE.ENGINEER) {
        actionPayload.dep = this.department.val;
        actionPayload.engineerLevel = this.engineerLevel.val;
        actionPayload.snils = this.snils.val || undefined;
        actionPayload.salary = this.salary.val;
        actionPayload.legalName = this.legalName.val || undefined;
      }

      try {
        await this.$store.dispatch("myprofiles/updateUser", actionPayload);
        await this.loadUser(this.id);
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Пользователь обновлён!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось обновить пользователя";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при обновлении пользователя!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style>
.delete-button {
  max-width: 1.1rem;
  height: 1.12rem;
  padding: 0;
  margin: 0;
}
</style>
